import BaseModule from './BaseModule.js';

class User extends BaseModule {
  async paginate({ page, per_page, term }) {
    return await this.get(`/back-office/users`, { params: { page, per_page, term } });
  }

  async create({ name, email, role }) {
    return await this.post(`/back-office/users`, {
      name,
      email,
      role,
    });
  }

  async update({ id, name, role, status, generate_new_password }) {
    return await this.patch(`/back-office/users/${id}`, {
      name,
      role,
      status,
      generate_new_password,
    });
  }

  async remove(id) {
    return await this.delete(`/back-office/users/${id}`);
  }
}

export default User;
