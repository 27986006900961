export const logo_light = [
  '900 60',
  `
<svg width="243" height="62" viewBox="0 0 243 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_361_230)">
        <path
            d="M48.0526 16.6838C51.789 11.122 55.7461 5.56181 59.5919 0H71.0201C67.5029 4.98501 64.0968 9.97001 60.6908 14.9437C57.2847 19.9175 53.7675 24.9025 50.471 29.8762H40.1417C38.1632 22.6098 36.1863 15.332 34.3173 8.05431C33.2185 3.91518 32.5579 1.99948 28.3833 0H43.6589C45.0881 5.56181 46.5156 11.122 48.0543 16.6838H48.0526Z"
            fill="url(#paint0_linear_361_230)" />
        <path
            d="M87.2385 29.8762C86.7987 28.5824 86.4684 27.2902 86.1397 25.9965H73.9414C73.0617 27.2902 72.0741 28.5824 71.3039 29.8762H59.9854C65.7002 21.3289 71.6342 12.7799 77.4586 4.2213C78.5575 2.66973 77.0188 0.752423 75.92 0H90.7558C92.0737 4.98501 93.3852 9.94262 94.7112 14.9437C96.0227 19.8917 97.2392 24.9025 98.6683 29.8762H87.2402H87.2385ZM84.3803 19.1054C83.7213 17.1414 83.1719 15.179 82.7321 13.2037C81.4141 15.179 79.985 17.143 78.7766 19.1054H84.3819H84.3803Z"
            fill="url(#paint1_linear_361_230)" />
        <path
            d="M99.3467 29.8762C100.885 22.5985 102.424 15.3208 103.963 8.04303C104.622 5.24441 104.183 1.45812 101.875 0H116.051C114.953 4.98501 113.963 9.97001 112.865 14.9437C111.766 19.9175 110.776 24.9025 109.678 29.8762H99.3483H99.3467Z"
            fill="url(#paint2_linear_361_230)" />
        <path
            d="M117.271 22.8032C118.102 18.8671 118.935 14.931 119.767 10.9948C120.065 9.48193 119.826 7.44055 118.637 6.64624H135.397C137.656 6.64624 139.172 8.5281 138.666 10.8434C138.102 13.4245 137.625 16.0249 137.061 18.6077C136.555 20.9229 134.208 22.8048 131.95 22.8048H117.271V22.8032ZM123.63 19.0765H129.87C130.702 19.0765 131.486 18.4498 131.654 17.678C132.081 15.7156 132.476 13.7403 132.903 11.7714C133.07 10.9997 132.547 10.3729 131.714 10.3729H125.474C124.879 13.2795 124.284 16.178 123.63 19.0781V19.0765Z"
            fill="url(#paint3_linear_361_230)" />
        <path
            d="M157.102 12.8574C156.866 14.1044 156.568 15.3499 156.331 16.5905H145.515C145.455 16.953 145.352 17.3187 145.278 17.678C145.115 18.4514 145.634 19.0765 146.348 19.0765H157.223C156.985 20.3236 156.688 21.5626 156.45 22.8032H142.781C140.462 22.8032 139.031 20.9133 139.512 18.6061C140.042 16.0684 140.581 13.5324 141.116 10.9948C141.413 9.48192 141.176 7.44055 139.987 6.64624H159.896C159.6 7.8933 159.361 9.1323 159.065 10.3729H146.822C146.643 11.2059 146.465 12.0324 146.287 12.859H157.102V12.8574Z"
            fill="url(#paint4_linear_361_230)" />
        <path
            d="M51.2527 55.1089C50.8129 57.4129 50.2635 59.7056 49.8236 61.9984H25.3191C21.0334 61.9984 18.2847 58.5069 19.1644 54.2389C20.152 49.4537 21.1945 44.6797 22.2418 39.8832C23.1714 35.6265 27.4072 32.1238 31.693 32.1238H56.1991C55.6497 34.4278 55.2098 36.7205 54.6604 39.0132H35.3197C33.8922 39.0132 32.4422 40.1733 32.1328 41.6008C31.3433 45.2404 30.6135 48.8946 29.8256 52.523C29.5146 53.9505 30.4846 55.1089 31.9137 55.1089H51.2544H51.2527Z"
            fill="url(#paint5_linear_361_230)" />
        <path
            d="M87.9056 32.1238C92.1913 32.1238 94.8675 35.6233 93.9491 39.872C93.5479 41.7264 93.1693 43.5616 92.7407 45.4096C91.9609 48.7705 89.2235 51.6642 86.0366 52.7228C87.5753 55.8146 89.0044 58.9081 90.5431 62H79.3341C77.9049 59.0612 76.3662 56.1095 74.9387 53.1578H68.125C67.4661 56.1079 66.9166 59.0596 66.2577 62H55.9268C57.4654 54.7223 59.0041 47.4445 60.5428 40.1668C61.2018 37.3682 60.6524 33.5819 58.5643 32.1238H87.9056ZM79.6643 46.2684C80.9823 46.2684 82.4485 45.0906 82.7417 43.6808C82.8899 42.9687 83.0382 42.2871 83.1816 41.5992C83.478 40.17 82.5226 39.0116 81.203 39.0116H71.0928C70.653 41.4332 70.1036 43.8564 69.5542 46.2668H79.6643V46.2684Z"
            fill="url(#paint6_linear_361_230)" />
        <path
            d="M94.6162 62C96.1549 54.7223 97.6936 47.4445 99.2323 40.1668C99.8912 37.3682 99.453 33.5819 97.1442 32.1238H111.321C110.222 37.1088 109.233 42.0938 108.134 47.0675C107.035 52.0412 106.046 57.0262 104.947 62H94.6178H94.6162Z"
            fill="url(#paint7_linear_361_230)" />
        <path
            d="M113.573 32.1238H143.024C147.2 32.1238 149.986 35.6233 149.068 39.872C148.667 41.7264 148.261 43.5551 147.859 45.4096C146.941 49.6583 142.694 53.1578 138.519 53.1578H123.244C122.585 56.1079 122.035 59.0596 121.375 62H111.045C112.584 54.7223 114.123 47.4445 115.661 40.1668C116.32 37.3682 115.881 33.5819 113.573 32.1238ZM124.673 46.2684H134.783C136.212 46.2684 137.567 45.0906 137.86 43.6808C138.009 42.9687 138.157 42.2871 138.3 41.5992C138.597 40.17 137.751 39.0116 136.322 39.0116H126.211C125.772 41.4332 125.222 43.8564 124.673 46.2668V46.2684Z"
            fill="url(#paint8_linear_361_230)" />
        <path
            d="M172.625 39.0132C171.086 46.6792 169.438 54.3452 167.79 62H157.57C159.218 54.3452 160.866 46.6792 162.405 39.0132H150.537C151.087 36.7205 151.526 34.4278 152.076 32.1238H186.033C185.484 34.4278 185.044 36.7205 184.494 39.0132H172.626H172.625Z"
            fill="url(#paint9_linear_361_230)" />
        <path
            d="M186.74 39.872C187.668 35.6265 191.794 32.1238 196.082 32.1238H216.852C221.028 32.1238 223.823 35.6265 222.895 39.872C221.845 44.6797 220.805 49.4537 219.818 54.2405C218.937 58.5085 214.652 62 210.478 62H189.708C185.422 62 182.783 58.5085 183.664 54.2405C184.652 49.4553 185.691 44.6797 186.742 39.872H186.74ZM206.74 55.0977C208.059 55.0977 209.54 53.9231 209.817 52.5117C210.529 48.8672 211.335 45.2404 212.124 41.6008C212.434 40.1733 211.465 39.0132 210.146 39.0132H199.705C198.276 39.0132 196.937 40.1733 196.628 41.6008C195.839 45.2404 195.109 48.8833 194.321 52.5117C194.011 53.9392 194.87 55.0977 196.299 55.0977H206.738H206.74Z"
            fill="url(#paint10_linear_361_230)" />
    </g>
    <defs>
        <linearGradient id="paint0_linear_361_230" x1="28.3833" y1="14.9373" x2="71.0201"
            y2="14.9373" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint1_linear_361_230" x1="59.9854" y1="0" x2="98.6667" y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint2_linear_361_230" x1="99.3467" y1="0" x2="116.051" y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint3_linear_361_230" x1="117.271" y1="14.7247" x2="138.764"
            y2="14.7247" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint4_linear_361_230" x1="139.418" y1="14.7247" x2="159.896"
            y2="14.7247" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint5_linear_361_230" x1="19" y1="47.0611" x2="56.1975" y2="47.0611"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint6_linear_361_230" x1="55.9268" y1="47.0611" x2="94.1296"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint7_linear_361_230" x1="94.6162" y1="47.0611" x2="111.319"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint8_linear_361_230" x1="111.047" y1="47.0611" x2="149.245"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint9_linear_361_230" x1="150.537" y1="47.0611" x2="186.033"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint10_linear_361_230" x1="183.494" y1="47.0611" x2="223.072"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>

    </defs>
</svg>
  `,
];

export const logo_dark = [
  '900 60',
  `
<svg width="243" height="62" viewBox="0 0 243 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_361_230)">
        <path
            d="M48.0526 16.6838C51.789 11.122 55.7461 5.56181 59.5919 0H71.0201C67.5029 4.98501 64.0968 9.97001 60.6908 14.9437C57.2847 19.9175 53.7675 24.9025 50.471 29.8762H40.1417C38.1632 22.6098 36.1863 15.332 34.3173 8.05431C33.2185 3.91518 32.5579 1.99948 28.3833 0H43.6589C45.0881 5.56181 46.5156 11.122 48.0543 16.6838H48.0526Z"
            fill="url(#paint0_linear_361_230)" />
        <path
            d="M87.2385 29.8762C86.7987 28.5824 86.4684 27.2902 86.1397 25.9965H73.9414C73.0617 27.2902 72.0741 28.5824 71.3039 29.8762H59.9854C65.7002 21.3289 71.6342 12.7799 77.4586 4.2213C78.5575 2.66973 77.0188 0.752423 75.92 0H90.7558C92.0737 4.98501 93.3852 9.94262 94.7112 14.9437C96.0227 19.8917 97.2392 24.9025 98.6683 29.8762H87.2402H87.2385ZM84.3803 19.1054C83.7213 17.1414 83.1719 15.179 82.7321 13.2037C81.4141 15.179 79.985 17.143 78.7766 19.1054H84.3819H84.3803Z"
            fill="url(#paint1_linear_361_230)" />
        <path
            d="M99.3467 29.8762C100.885 22.5985 102.424 15.3208 103.963 8.04303C104.622 5.24441 104.183 1.45812 101.875 0H116.051C114.953 4.98501 113.963 9.97001 112.865 14.9437C111.766 19.9175 110.776 24.9025 109.678 29.8762H99.3483H99.3467Z"
            fill="url(#paint2_linear_361_230)" />
        <path
            d="M117.271 22.8032C118.102 18.8671 118.935 14.931 119.767 10.9948C120.065 9.48193 119.826 7.44055 118.637 6.64624H135.397C137.656 6.64624 139.172 8.5281 138.666 10.8434C138.102 13.4245 137.625 16.0249 137.061 18.6077C136.555 20.9229 134.208 22.8048 131.95 22.8048H117.271V22.8032ZM123.63 19.0765H129.87C130.702 19.0765 131.486 18.4498 131.654 17.678C132.081 15.7156 132.476 13.7403 132.903 11.7714C133.07 10.9997 132.547 10.3729 131.714 10.3729H125.474C124.879 13.2795 124.284 16.178 123.63 19.0781V19.0765Z"
            fill="url(#paint3_linear_361_230)" />
        <path
            d="M157.102 12.8574C156.866 14.1044 156.568 15.3499 156.331 16.5905H145.515C145.455 16.953 145.352 17.3187 145.278 17.678C145.115 18.4514 145.634 19.0765 146.348 19.0765H157.223C156.985 20.3236 156.688 21.5626 156.45 22.8032H142.781C140.462 22.8032 139.031 20.9133 139.512 18.6061C140.042 16.0684 140.581 13.5324 141.116 10.9948C141.413 9.48192 141.176 7.44055 139.987 6.64624H159.896C159.6 7.8933 159.361 9.1323 159.065 10.3729H146.822C146.643 11.2059 146.465 12.0324 146.287 12.859H157.102V12.8574Z"
            fill="url(#paint4_linear_361_230)" />
        <path
            d="M51.2527 55.1089C50.8129 57.4129 50.2635 59.7056 49.8236 61.9984H25.3191C21.0334 61.9984 18.2847 58.5069 19.1644 54.2389C20.152 49.4537 21.1945 44.6797 22.2418 39.8832C23.1714 35.6265 27.4072 32.1238 31.693 32.1238H56.1991C55.6497 34.4278 55.2098 36.7205 54.6604 39.0132H35.3197C33.8922 39.0132 32.4422 40.1733 32.1328 41.6008C31.3433 45.2404 30.6135 48.8946 29.8256 52.523C29.5146 53.9505 30.4846 55.1089 31.9137 55.1089H51.2544H51.2527Z"
            fill="url(#paint5_linear_361_230)" />
        <path
            d="M87.9056 32.1238C92.1913 32.1238 94.8675 35.6233 93.9491 39.872C93.5479 41.7264 93.1693 43.5616 92.7407 45.4096C91.9609 48.7705 89.2235 51.6642 86.0366 52.7228C87.5753 55.8146 89.0044 58.9081 90.5431 62H79.3341C77.9049 59.0612 76.3662 56.1095 74.9387 53.1578H68.125C67.4661 56.1079 66.9166 59.0596 66.2577 62H55.9268C57.4654 54.7223 59.0041 47.4445 60.5428 40.1668C61.2018 37.3682 60.6524 33.5819 58.5643 32.1238H87.9056ZM79.6643 46.2684C80.9823 46.2684 82.4485 45.0906 82.7417 43.6808C82.8899 42.9687 83.0382 42.2871 83.1816 41.5992C83.478 40.17 82.5226 39.0116 81.203 39.0116H71.0928C70.653 41.4332 70.1036 43.8564 69.5542 46.2668H79.6643V46.2684Z"
            fill="url(#paint6_linear_361_230)" />
        <path
            d="M94.6162 62C96.1549 54.7223 97.6936 47.4445 99.2323 40.1668C99.8912 37.3682 99.453 33.5819 97.1442 32.1238H111.321C110.222 37.1088 109.233 42.0938 108.134 47.0675C107.035 52.0412 106.046 57.0262 104.947 62H94.6178H94.6162Z"
            fill="url(#paint7_linear_361_230)" />
        <path
            d="M113.573 32.1238H143.024C147.2 32.1238 149.986 35.6233 149.068 39.872C148.667 41.7264 148.261 43.5551 147.859 45.4096C146.941 49.6583 142.694 53.1578 138.519 53.1578H123.244C122.585 56.1079 122.035 59.0596 121.375 62H111.045C112.584 54.7223 114.123 47.4445 115.661 40.1668C116.32 37.3682 115.881 33.5819 113.573 32.1238ZM124.673 46.2684H134.783C136.212 46.2684 137.567 45.0906 137.86 43.6808C138.009 42.9687 138.157 42.2871 138.3 41.5992C138.597 40.17 137.751 39.0116 136.322 39.0116H126.211C125.772 41.4332 125.222 43.8564 124.673 46.2668V46.2684Z"
            fill="url(#paint8_linear_361_230)" />
        <path
            d="M172.625 39.0132C171.086 46.6792 169.438 54.3452 167.79 62H157.57C159.218 54.3452 160.866 46.6792 162.405 39.0132H150.537C151.087 36.7205 151.526 34.4278 152.076 32.1238H186.033C185.484 34.4278 185.044 36.7205 184.494 39.0132H172.626H172.625Z"
            fill="url(#paint9_linear_361_230)" />
        <path
            d="M186.74 39.872C187.668 35.6265 191.794 32.1238 196.082 32.1238H216.852C221.028 32.1238 223.823 35.6265 222.895 39.872C221.845 44.6797 220.805 49.4537 219.818 54.2405C218.937 58.5085 214.652 62 210.478 62H189.708C185.422 62 182.783 58.5085 183.664 54.2405C184.652 49.4553 185.691 44.6797 186.742 39.872H186.74ZM206.74 55.0977C208.059 55.0977 209.54 53.9231 209.817 52.5117C210.529 48.8672 211.335 45.2404 212.124 41.6008C212.434 40.1733 211.465 39.0132 210.146 39.0132H199.705C198.276 39.0132 196.937 40.1733 196.628 41.6008C195.839 45.2404 195.109 48.8833 194.321 52.5117C194.011 53.9392 194.87 55.0977 196.299 55.0977H206.738H206.74Z"
            fill="url(#paint10_linear_361_230)" />
    </g>
    <defs>
        <linearGradient id="paint0_linear_361_230" x1="28.3833" y1="14.9373" x2="71.0201"
            y2="14.9373" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint1_linear_361_230" x1="59.9854" y1="0" x2="98.6667" y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint2_linear_361_230" x1="99.3467" y1="0" x2="116.051" y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint3_linear_361_230" x1="117.271" y1="14.7247" x2="138.764"
            y2="14.7247" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint4_linear_361_230" x1="139.418" y1="14.7247" x2="159.896"
            y2="14.7247" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint5_linear_361_230" x1="19" y1="47.0611" x2="56.1975" y2="47.0611"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint6_linear_361_230" x1="55.9268" y1="47.0611" x2="94.1296"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint7_linear_361_230" x1="94.6162" y1="47.0611" x2="111.319"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint8_linear_361_230" x1="111.047" y1="47.0611" x2="149.245"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint9_linear_361_230" x1="150.537" y1="47.0611" x2="186.033"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
        <linearGradient id="paint10_linear_361_230" x1="183.494" y1="47.0611" x2="223.072"
            y2="47.0611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>

    </defs>
</svg>
  `,
];
