import BaseModule from './BaseModule.js';

class Contract extends BaseModule {
  async paginate({ page, per_page, role, term }) {
    return await this.get(`back-office/contracts`, { params: { page, per_page, role, term } });
  }

  async fetch(id) {
    return await this.get(`back-office/contracts/${id}`);
  }

  async fetchImagePath(id) {
    return await this.get(`back-office/contracts/view-path/${id}`);
  }

  async create(contract) {
    return await this.post(`/back-office/contracts`, contract);
  }

  async update(contract) {
    return await this.patch(`/back-office/contracts/${contract.id}`, contract);
  }
}

export default Contract;
