import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilAddressBook,
  cilBriefcase,
  cilDataTransferDown,
  cilDescription,
  cilLockLocked,
  cilNotes,
  cilPeople,
  cilShieldAlt,
  cilSpeedometer,
  cilSpreadsheet,
  cilWallet,
} from '@coreui/icons';
import { CNavItem } from '@coreui/react';

const RoutesNav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders/list-orders',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    component: CNavItem,
    name: 'Users',
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER'],
    to: '/users/list-users',
  },
  {
    component: CNavItem,
    name: 'Customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/customers/list-customers',
  },
  {
    component: CNavItem,
    name: 'Wallets',
    icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/wallets/list-wallets',
  },
  {
    component: CNavItem,
    name: 'Pix Cash Ins',
    icon: <CIcon icon={cilDataTransferDown} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/pix-cash-ins/list-pix-cash-ins',
  },
  {
    component: CNavItem,
    name: 'Payers',
    icon: <CIcon icon={cilBriefcase} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/payers/list-payers',
  },
  {
    component: CNavItem,
    name: 'Tiers',
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/tiers/list-tiers',
  },
  {
    component: CNavItem,
    name: 'Withdrawals',
    icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/withdrawals/list-withdrawals',
  },
  {
    component: CNavItem,
    name: 'Contracts',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/contracts/list-contracts',
  },
  {
    component: CNavItem,
    name: 'Configurations',
    icon: <CIcon icon={cilLockLocked} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    to: '/configs/list-configs',
  },
];

export default RoutesNav;
