export const sygnet = [
  '70 60',
  `
<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M28.2976 33.3373C33.2926 25.8908 38.5805 18.4465 43.722 11H59C54.2979 17.6742 49.7445 24.3484 45.191 31.0076C40.6376 37.6667 35.9355 44.3409 31.5285 51H17.7195C15.0745 41.2713 12.4316 31.5274 9.933 21.7836C8.46401 16.2419 7.58304 13.677 2 11H22.4216C24.3322 18.4465 26.2406 25.8908 28.2976 33.3373Z"
        fill="url(#paint0_linear_2043_5437)" />
    <defs>
        <linearGradient id="paint0_linear_2043_5437" x1="2" y1="30.9989" x2="59.0022" y2="30.9989"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#BEFF31" />
            <stop offset="0.98" stop-color="#98D431" />
        </linearGradient>
    </defs>
</svg>
  `,
];
