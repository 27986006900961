import { createStatusColorObject, createStatusTextObject } from './UtilsFunc';
import {
  cilArrowBottom,
  cilArrowThickLeft,
  cilBadge,
  cilCash,
  cilCheck,
  cilCheckCircle,
  cilCircle,
  cilClock,
  cilCode,
  cilFlagAlt,
  cilInfo,
  cilPencil,
  cilReload,
  cilWarning,
  cilX,
} from '@coreui/icons';

const ADMIN_ROLE = ['ADMIN'];
const USER_ROLE = ['USER', 'ADMIN'];
const ANALYZE_ROLE = ['ANALYZE', 'ADMIN'];

const ORDER_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  WITHDRAWAL_REQUESTED: 'WITHDRAWAL_REQUESTED',
  WITHDRAWAL_FAILED: 'WITHDRAWAL_FAILED',
  FAILED: 'FAILED',
  DONE: 'DONE',
  EXPIRED: 'EXPIRED',
  REFUNDED: 'REFUNDED',
  REFUND_REQUESTED: 'REFUND_REQUESTED',
  REFUND_FAILED: 'REFUND_FAILED',
};

const ORDER_TEXT = createStatusTextObject(ORDER_STATUS, '');

const ORDER_COLORS = createStatusColorObject(ORDER_STATUS, {
  PENDING: 'primary',
  PAID: 'success',
  WITHDRAWAL_REQUESTED: 'secondary',
  WITHDRAWAL_FAILED: 'danger',
  FAILED: 'danger',
  DONE: 'success',
  EXPIRED: 'warning',
  REFUNDED: 'info',
  REFUND_REQUESTED: 'secondary',
  REFUND_FAILED: 'secondary',
});

const PIX_STATUS = {
  RECEIVED: 'RECEIVED',
  REFUND_REQUESTED: 'REFUND_REQUESTED',
  REFUND_FAILED: 'REFUND_FAILED',
  REFUNDED: 'REFUNDED',
};

const PIX_COLORS = createStatusColorObject(PIX_STATUS, {
  RECEIVED: 'success',
  REFUND_REQUESTED: 'primary',
  REFUND_FAILED: 'danger',
  REFUNDED: 'warning',
});

const CONTRACT_STATUS = {
  REQUESTED: 'REQUESTED',
  IN_ANALYSIS: 'IN_ANALYSIS',
  RECUSED: 'RECUSED',
  DONE: 'DONE',
};

const CONTRACT_COLORS = createStatusColorObject(CONTRACT_STATUS, {
  REQUESTED: 'info',
  IN_ANALYSIS: 'warning',
  RECUSED: 'danger',
  DONE: 'primary',
});

const VALIDATION_COLORS = {
  ACCEPTED: 'success',
  REJECTED: 'danger',
  PENDING: 'info',
};

const STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  BLOCKED: 'Blocked',
};

const STATUS_COLORS = createStatusColorObject(STATUS, {
  RECEIVED: 'success',
  REFUND_REQUESTED: 'primary',
  REFUND_FAILED: 'danger',
  REFUNDED: 'warning',
});

const WITHDRAWAL_STATUS = {
  REGISTERED: 'REGISTERED',
  SENT: 'SENT',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const WITHDRAWAL_COLORS = createStatusColorObject(WITHDRAWAL_STATUS, {
  REGISTERED: 'warning',
  SENT: 'info',
  PENDING: 'primary',
  SUCCESS: 'success',
  FAILED: 'danger',
});

const EVENT_STATUS = {
  DONE: 'DONE',
  SUCCEEDED: 'SUCCEEDED',
  ACCEPTED: 'SUCCESS',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
  UPDATED: 'UPDATED',
  WARNING: 'WARNING',
  INFO: 'INFO',
  NOTE: 'NOTE',
  EXPIRED: 'EXPIRED',
  REFUNDED: 'REFUNDED',
  CREATED: 'CREATED',
  REFUND_FAILED: 'REFUND_FAILED',
  PIX_CASH_IN_RECEIVED: 'PIX_CASH_IN_RECEIVED',
  REFUND_REQUESTED: 'REFUND_REQUESTED',
  WITHDRAWAL_REQUESTED: 'WITHDRAWAL_REQUESTED',
  WITHDRAWAL_REATTEMPT_REQUESTED: 'WITHDRAWAL_REATTEMPT_REQUESTED',
  REATTEMPT_REQUESTED: 'REATTEMPT_REQUESTED',
  MAX_WITHDRAWALS_ATTEMPT: 'MAX_WITHDRAWALS_ATTEMPT',
  LOGIN_SUCCEEDED: 'LOGIN_SUCCEEDED',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  API_CREDENTIAL_CREATED: 'API_CREDENTIAL_CREATED',
  API_CREDENTIAL_DELETED: 'API_CREDENTIAL_DELETED',
  REGISTER_ORDER_CRYPTO_PURCHASE_FAILED: 'REGISTER_ORDER_CRYPTO_PURCHASE_FAILED',
  UPDATE_ORDER_STATUS_WITHDRAWAL_FAILED: 'UPDATE_ORDER_STATUS_WITHDRAWAL_FAILED',
  HANDLE_CRYPTO_PURCHASE_ERROR: 'HANDLE_CRYPTO_PURCHASE_ERROR',
};

const EVENT_ICONS = {
  DONE: cilBadge,
  DEFAULT: cilCircle,
  ACCEPTED: cilCheck,
  FAILED: cilX,
  UPDATED: cilPencil,
  WARNING: cilWarning,
  LOG: cilCode,
  NOTE: cilInfo,
  EXPIRED: cilClock,
  REFUNDED: cilArrowThickLeft,
  CREATED: cilFlagAlt,
  REFUND_FAILED: cilX,
  PIX_CASH_IN_RECEIVED: cilCash,
  REFUND_REQUESTED: cilX,
  WITHDRAWAL_REQUESTED: cilArrowBottom,
  WITHDRAWAL_REATTEMPT_REQUESTED: cilReload,
  REATTEMPT_REQUESTED: cilReload,
  MAX_WITHDRAWALS_ATTEMPT: cilWarning,
  REJECTED: cilX,
  LOGIN_SUCCEEDED: cilCheckCircle,
  PAYMENT_CONFIRMED: cilCheckCircle,
  API_CREDENTIAL_CREATED: cilCheckCircle,
  API_CREDENTIAL_DELETED: cilCheckCircle,
  REGISTER_ORDER_CRYPTO_PURCHASE_FAILED: cilX,
  UPDATE_ORDER_STATUS_WITHDRAWAL_FAILED: cilX,
  HANDLE_CRYPTO_PURCHASE_ERROR: cilX,
};

const EVENT_COLORS = createStatusColorObject(EVENT_STATUS, {
  DONE: 'success',
  DEFAULT: 'light',
  SUCCEEDED: 'success',
  ACCEPTED: 'success',
  FAILED: 'danger',
  REJECTED: 'danger',
  UPDATED: 'info',
  WARNING: 'warning',
  LOG: 'light',
  NOTE: 'info',
  EXPIRED: 'warning',
  REFUNDED: 'primary',
  CREATED: 'success',
  REFUND_FAILED: 'danger',
  PIX_CASH_IN_RECEIVED: 'success',
  REFUND_REQUESTED: 'danger',
  WITHDRAWAL_REQUESTED: 'info',
  WITHDRAWAL_REATTEMPT_REQUESTED: 'warning',
  REATTEMPT_REQUESTED: 'primary',
  MAX_WITHDRAWALS_ATTEMPT: 'warning',
  LOGIN_SUCCEEDED: 'success',
  PAYMENT_CONFIRMED: 'success',
  API_CREDENTIAL_CREATED: 'success',
  API_CREDENTIAL_DELETED: 'success',
  REGISTER_ORDER_CRYPTO_PURCHASE_FAILED: 'danger',
  UPDATE_ORDER_STATUS_WITHDRAWAL_FAILED: 'danger',
  HANDLE_CRYPTO_PURCHASE_ERROR: 'danger',
});

export {
  ADMIN_ROLE,
  USER_ROLE,
  ANALYZE_ROLE,
  ORDER_STATUS,
  ORDER_TEXT,
  ORDER_COLORS,
  PIX_STATUS,
  PIX_COLORS,
  CONTRACT_STATUS,
  CONTRACT_COLORS,
  VALIDATION_COLORS,
  STATUS_COLORS,
  STATUS,
  WITHDRAWAL_STATUS,
  WITHDRAWAL_COLORS,
  EVENT_STATUS,
  EVENT_COLORS,
  EVENT_ICONS,
};
