import React from 'react';
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownItemPlain,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import { cilAccountLogout } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import avatar6 from './../../assets/images/avatars/6.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setAuthenticated } from 'src/reducers/AuthReducer';
import { shortedName } from 'src/utils/UtilsFunc';
import { useTranslation } from 'react-i18next';

const AppHeaderDropdown = () => {
  const { authenticated, profile } = useSelector((state) => ({
    authenticated: state.auth.authenticated,
    profile: state.user.profile,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      dispatch(
        setAuthenticated({
          ...authenticated,
          isLogged: false,
          last_login: JSON.stringify(new Date()),
        }),
      );

      navigate('/');
    } catch (error) {
      console.log('HANDLE LOGOUT ERROR => ', error);
    }
  };

  const greeting = () => {
    const now = new Date().getHours();
    if (now > 18) {
      return t('greeting.night');
    }
    if (now > 12) {
      return t('greeting.afternoom');
    }
    return t('greeting.morning');
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0 pe-0" caret={false}>
        <CAvatar src={avatar6} size="sm" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-body-secondary fw-semibold py-2">
          <CDropdownItemPlain>
            {greeting()}, {shortedName(profile?.name)}!
          </CDropdownItemPlain>
        </CDropdownHeader>
        <CDropdownDivider />
        <CDropdownItem href="/" onClick={handleLogout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          {t('common.logout')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
